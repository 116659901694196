html, body {
  max-width: 100%;
  overflow-x: hidden;
}
/*.test{*/
/*  background:black;*/
/*}*/
/*.container-se-btn-sel{*/
/*  display: flex;*/
/*}*/
/*.container-se-btn-sel .text-field-position .search-input {*/
/*  display: inline-block;*/
/*  height: 40px;*/
/*  width: 100%;*/
/*  font-size: 15px;*/
/*  color: #666666;*/
/*  text-indent: 8px;*/
/*  border-left: none;*/
/*  border-right: none;*/
/*  border-top: none;*/
/*  border-bottom: 1px solid #707070;*/
/*  outline: none;*/
/*}*/

/*.container-se-btn-sel .text-field-position {*/
/*  position: relative;*/
/*}*/

/*.container-se-btn-sel .text-field-position span {*/
/*  position: absolute;*/
/*  right: 0.5em;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  line-height: 95px;*/
/*  font-size: 1.4em;*/
/*  font-weight: bold;*/
/*}*/

/*.container-se-btn-sel .text-field-position .search-word{*/
/*  font-size: 0.75em;*/
/*  color: #000000;*/
/*  font-weight: bold;*/

/*}*/

/*.container-se-btn-sel .select-language{*/
/*  width: 80px;*/
/*  height: 30px;*/
/*  border-radius: 5px;*/
/*  font-weight: bold;*/
/*  font-size: 0.75em;*/
/*  outline: none;*/
/*  margin-top: 34px;*/
/*}*/

/*.container-se-btn-sel .buttons-header{*/
/*  margin-top: 34px;*/
/*}*/

/*.container-se-btn-sel .buttons-header .btn-newDomain{*/
/*  border-radius: 5px;*/
/*  background: #FF6B00;*/
/*  outline: none;*/
/*  border: none;*/
/*  height: 30px;*/
/*  font-size: 0.75em;*/
/*  font-weight: 400;*/
/*  color: #000000;*/
/*}*/

/*.container-se-btn-sel .buttons-header .btn-Logout{*/
/*  border-radius: 5px;*/
/*  background: #3E3E3E;*/
/*  outline: none;*/
/*  border: none;*/
/*  height: 30px;*/
/*  font-size: 0.75em;*/
/*  font-weight: bold;*/
/*  color: #ffffff;*/
/*}*/
/*.container-se-btn-sel .buttons-header .btn-Logout:hover{*/
/*  background: #ffffff;*/
/*  color: #000000;*/
/*}*/
/*
Table Data
 */

.table-data .table-head{
  background: #606060;
  color: #ffffff;
}

.table-data .table-body{
border-top: none;
}

.table-data .table-striped .vertical-line{
  color: #D0D0D0;
  font-size: 21px;
  font-weight: 100;
}

.sort-icons{
  transform: translateY(-4px);
}


/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

#gridContainer{
  margin-left: 5em;
  margin-right: 5em;
}

.dx-datagrid-header-panel{
  margin-top: 6rem;
  margin-bottom: 1.25rem;
}

.btn-newDomain{
  border-radius: 5px;
  background: #FF6B00;
  outline: none;
  border: none;
  /*height: 30px;*/
  font-size: 0.75em;
  font-weight: 400;
  color: #ffffff;
  margin-right: 1.25rem;
}
.btn-newDomain:hover{
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
}
.btn-Logout{
  border-radius: 5px;
  background: #3E3E3E;
  outline: none;
  border: none;
  /*height: 30px;*/
  font-size: 0.75em;
  /*font-weight: bold;*/
  color: #ffffff;
  margin-right: 1.45rem;
}
.btn-Logout:hover{
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
}
.item-select-language .dx-toolbar-item-content .select-language{
  width: 80px;
  height: 30px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.75em;
  outline: none;
  border: 1px solid #000000;
  margin-right: 1.25rem;
  margin-left: 2rem;
}
.item-select-language .dx-toolbar-item-content .select-language .dx-dropdowneditor-input-wrapper .dx-texteditor-container .dx-texteditor-input-container .dx-texteditor-input{
  padding-top: 0;
  padding-bottom: 7px;
}
.item-search-domain .dx-toolbar-item-content .dx-datagrid-search-panel{
  border: none;
  width: 100% !important;
  border-bottom: 1px solid #707070;
  outline: none;
  border-radius: 0;

}


/* Icon von Such field */
.item-search-domain .dx-toolbar-item-content .dx-datagrid-search-panel .dx-texteditor-container .dx-texteditor-input-container .dx-icon-search{
display: none;
}

.item-search-domain .dx-toolbar-item-content .dx-datagrid-search-panel .dx-texteditor-container .dx-texteditor-input-container .dx-texteditor-input{
  padding-left: 0;
}
.item-search-domain .dx-toolbar-item-content .dx-datagrid-search-panel .dx-texteditor-container .dx-texteditor-input-container .dx-placeholder:before{
  padding-left: 0;
}


/*.item-search-domain .dx-toolbar-item-content .dx-datagrid-search-panel .dx-texteditor-container:before{*/
/*  content: 'Search';*/
/*  position: absolute;*/
/*  top: -8px;*/
/*  color: #000000;*/
/*  font-size: 0.75em;*/
/*  font-weight: bold;*/
/*}*/

.item-search-domain .dx-toolbar-item-content .dx-datagrid-search-panel .dx-texteditor-container .dx-texteditor-input-container .dx-texteditor-buttons-container .dx-clear-button-area .dx-icon-clear:before{
  /*Clear Icon muss später ändern ich habe noch keine Icon afür */
}

.dx-widget .dx-datagrid .dx-datagrid-headers{
  background: #606060;
  color: #ffffff;
  font-size: 0.75em;
  font-weight: bold;
}

.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-select .dx-checkbox{
  /*pointer-events: none;*/

  display: inline-block;
}

.dx-widget .dx-datagrid .dx-column-lines > td{
  border-right: none !important;
  border-left: none !important;
  height: 43px;
  padding-top: 13px;
  font-size: 12px;

}

.dx-widget .dx-datagrid .dx-column-lines > td:before{
  content: '│';
  margin-right: 0.8rem;
  color: #D0D0D0;

}

/*mit first-child lösche ich die pseudo element (│) befor cheakBox*/
.dx-widget .dx-datagrid .dx-column-lines > td:first-child:before{
  display: none;
}

[aria-describedby='dx-col-7'] {
  font-size: 10px;
  font-weight: bold;
}

.dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container #info-for-pager{
/*color: red;*/
  font-size: 14px;
  font-weight: 500;
}

.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection{
  background-color: rgb(255, 107, 0);
  color: #ffffff;
}

.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages{
  text-align: center;
  float: none;
}

.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit{
  /*border-radius: 10px;*/
  text-align: right !important;
  padding-top: 5px;
  /*background-image: -webkit-linear-gradient(right, #fc0, #fc0 70%, transparent 60%,transparent 100%);*/
}
.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit .actionsButtons{
display: contents;
}
.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit .dx-link{
  color: #000000;
  font-size: 20px;
  font-weight: bold;

}

#gridContainer .dx-datagrid .dx-datagrid-rowsview{
  border: none;
}

.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit .dx-link-edit{
  /*margin-left: 0;*/
  /*margin-right: 20px;*/
}

.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit .dx-icon-copy{
  /*margin-right: 20px;*/
}
.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit .dx-link-delete{
  /*margin-right: -58px;*/
}

/*.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages #info-for-pager{*/
/*display: none;*/
/*}*/
.dx-datagrid .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container .dx-toolbar-before .item-iscope-logo .dx-item-content .iscope-logo img{
  width: 75px;
  height: 75px;
}


.dx-datagrid .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container .dx-toolbar-before .item-iscope-logo .dx-item-content .iscope-logo{
  transform: translateY(-35px);
}

#dx-toolbar-id .wrapper_domain_language_search{
  display: flex;
  flex-direction: row-reverse;
}
#dx-toolbar-id .wrapper-Logout-delete-item .dx-toolbar-item-content{
  padding-bottom: 3px;
}

#dx-toolbar-id .wrapper-Logout-delete-item .dx-toolbar-item-content .btn-delete .dx-button-content{
  font-size: .75em;
  padding: 5px 10px 5px 5px;
}

#gridContainer .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-freespace-row{
height: 0 !important;
}
.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-freespace-row td{
display: none;
}
/*#gridContainer .dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table tbody .dx-row .dx-datagrid-action .dx-column-indicators .dx-sort:before{*/
/*  color: rgb(255, 107, 0);;*/
/*}*/
#gridContainer .dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table tbody .dx-row .dx-datagrid-action .dx-column-indicators .dx-sort-up:before{
  content: "\f014" !important;
}
#gridContainer .dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table tbody .dx-row .dx-datagrid-action .dx-column-indicators .dx-sort-down:before{
  content: "\f016" !important;
}

#gridContainer .dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table tbody .dx-row .dx-command-edit-with-icons{
text-align: left !important;
}
#gridContainer .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-edit-with-icons{
  text-align: left !important;
}
@media screen and (max-width: 992px) {
  .dx-datagrid .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-item-invisible{
    display: block !important;
  }

  .dx-datagrid .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-menu-container{
    display: none;
  }

  #dx-toolbar-id .wrapper_language_domain{
    display: flex;
  }

  #dx-toolbar-id .wrapper_domain_language_search{
    display: flex;
    flex-direction: row-reverse;
  }

  #dx-toolbar-id .wrapper-Logout-delete-item{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 25px;
  }

  #dx-toolbar-id .wrapper-Logout-delete-item .dx-toolbar-item-content{
    display: flex;
    flex-direction: row-reverse;
  }
  .dx-datagrid .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container .dx-toolbar-after{
    display: flex;
    flex-direction: column-reverse;
  }

  #dx-toolbar-id .wrapper_language_domain .item-add-domain .dx-toolbar-item-content .btn-newDomain{
    margin-right: 0;
  }
  #dx-toolbar-id .wrapper-Logout-delete-item .dx-toolbar-item-content .btn-Logout{
    margin-right: 0;
    width: 96px;
  }

  #dx-toolbar-id .wrapper-Logout-delete-item .dx-toolbar-item-content .btn-delete{
    margin-right: 1.55rem;
    height: 30px;
    width: 96px;
    font-size: .73em;
    font-weight: bold;
  }

  .item-select-language .dx-toolbar-item-content .select-language{
    width: 96px;
  }


  .dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-adaptive:before{
    color: deeppink;
    display: none;
  }

  .dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-adaptive .dx-datagrid-adaptive-more{
    color: #000000;
  }

  /*.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-command-adaptive .dx-datagrid-adaptive-more:before{*/
  /*  !*font-family: 'Font Awesome 6 Free';*!*/
  /*  content: "\f010"; !* FontAwesome Unicode chevron-right *!*/
  /*  font-weight: 900;*/

  /*}*/

  /*.dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row .dx-cell-focus-disabled .dx-datagrid-adaptive-more:before{*/
  /*  !*font-family: 'Font Awesome 6 Free';*!*/
  /*  content: "\f016"; !* FontAwesome Unicode  chevron-down*!*/
  /*  font-weight: 900;*/

  /*}*/

  td[aria-label="Display additional data"] .dx-datagrid-adaptive-more:before{
      content: "\f010"; /* FontAwesome Unicode chevron-right */
    font-weight: 900;
  }
  td[aria-label="Hide additional data"] .dx-datagrid-adaptive-more:before{
      content: "\f016"; /* FontAwesome Unicode  chevron-down*/
    font-weight: 900;
  }
  .dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row td .dx-form .dx-layout-manager .dx-widget .dx-box-flex .dx-item .dx-item-content .dx-field-item .dx-field-item-label .dx-field-item-label-content .dx-field-item-label-text{
    font-size: 12px;
    font-weight: bold;
  }
}

@media screen and (max-width: 1200px) {
  td[aria-label="Display additional data"] .dx-datagrid-adaptive-more:before{
    content: "\f010"; /* FontAwesome Unicode chevron-right */
    font-weight: 900;
  }
  td[aria-label="Hide additional data"] .dx-datagrid-adaptive-more:before{
    content: "\f016"; /* FontAwesome Unicode  chevron-down*/
    font-weight: 900;
  }
  .dx-widget .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table tbody .dx-row td .dx-form .dx-layout-manager .dx-widget .dx-box-flex .dx-item .dx-item-content .dx-field-item .dx-field-item-label .dx-field-item-label-content .dx-field-item-label-text{
    font-size: 12px;
    font-weight: bold;
  }
}